@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap";
* {
  vertical-align: baseline;
  box-sizing: border-box;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 100%;
  overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: var(--base-colour);
  touch-action: manipulation;
  position: fixed;
  overflow: hidden;
}

.liked-banner-container {
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.liked-banner-container .liked-status {
  z-index: 100;
  pointer-events: none;
  border: .5rem solid;
  border-radius: 5px;
  font-size: 50px;
  font-weight: 700;
  display: none;
  position: absolute;
  top: 6rem;
}

.liked-banner-container .liked-status.liked {
  right: 1rem;
  left: "";
  color: #55dd6e;
  border-color: #55dd6e;
  display: block;
  transform: rotate(15deg);
}

.liked-banner-container .liked-status.liked:before {
  content: "Liked";
}

.liked-banner-container .liked-status.disliked {
  right: "";
  color: #ff3b38;
  border-color: #ff3b38;
  display: block;
  left: 1rem;
  transform: rotate(-15deg);
}

.liked-banner-container .liked-status.disliked:before {
  content: "Disliked";
}

.size-warning-container {
  height: 100vh;
  width: 100vw;
  background-color: var(--base-colour);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.size-warning-container.visible {
  display: block;
}

.size-warning-container h2 {
  padding-bottom: 1rem;
  font-size: 32px;
}

.size-warning-container p {
  color: var(--main-font-colour);
  font-size: 16px;
}

.loading-container {
  height: 100%;
  width: 100%;
  background-color: var(--base-colour);
  z-index: 9999;
  opacity: 1;
  color: var(--main-font-colour);
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-container p {
  font-size: 26px;
  font-weight: 600;
}

.loading-container.hidden {
  opacity: 0;
  pointer-events: none;
}

.liked-movies-container {
  height: 100vh;
  width: 100vw;
  background-color: var(--base-colour);
  color: #fff;
  z-index: 999;
  transition: all .25s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: scroll;
  transform: translateY(0);
}

.liked-movies-container.hidden {
  transform: translateY(110vh);
}

.liked-movies-container .empty-message {
  width: 100%;
  text-align: center;
  color: var(--main-font-colour);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.liked-movies-container .liked-movie-header {
  width: 100%;
  text-align: center;
  height: 4rem;
  color: var(--main-font-colour);
  background-color: var(--base-colour);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  right: 0;
}

.liked-movies-container .liked-movie-header .close-liked-movies {
  fill: #fff;
  width: 30px;
  height: 30px;
  z-index: 999;
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.liked-movies-container .liked-movie-header .liked-header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.liked-movies-container .liked-movies-list {
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem 1rem 5rem;
  display: grid;
}

.liked-movies-container .liked-movies-list .liked-movie-container {
  width: 100%;
  height: auto;
  justify-content: space-around;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.liked-movies-container .liked-movies-list .liked-movie-container .liked-poster {
  aspect-ratio: 2 / 3;
  background-size: cover;
  border-radius: 10px;
}

.liked-movies-container .liked-movies-list .liked-movie-container * {
  flex: 1;
}

.notification-container {
  width: 100%;
  height: 100%;
  background-color: var(--transparent-base);
  z-index: 9999;
  color: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  transition: all .25s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100vh);
}

.notification-container h2 {
  font-size: 26px;
}

.notification-container img {
  border-radius: var(--border-radius);
  width: 60%;
  margin: 2rem 0;
}

.notification-container.visible {
  transform: translate(0);
}

.notification-container #dismiss-notification {
  color: var(--faded-font-colour);
  padding-top: 2rem;
  font-size: 10px;
}

.header {
  width: 100%;
  height: 50px;
  color: var(--main-font-colour);
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  display: flex;
  position: fixed;
  top: 0;
  overflow: visible;
}

.header .nav-content-centre {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header .nav-content-centre h2 {
  font-size: 14px;
}

.header .nav-content-centre p {
  font-size: 10px;
  font-weight: 700;
}

.header .nav-icon, .header .spacer {
  height: 30px;
  width: 30px;
}

.header .menu-container {
  -webkit-tap-highlight-color: #f000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: visible;
}

.header .menu-container .menu-panel {
  width: 200px;
  background-color: var(--base-colour);
  border-radius: 10px;
  padding: 1rem;
  font-size: 14px;
  transition: all .25s;
  position: absolute;
  top: 2rem;
  right: 1rem;
  box-shadow: 0 10px 55px -5px #0000004d;
}

.header .menu-container .menu-panel.hidden {
  transform-origin: 100% 0;
  transform: scale(0);
}

.header .menu-container .menu-panel ul {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

.header .menu-container .menu-panel ul li {
  width: 100%;
  font-weight: 400;
}

.header .menu-container .menu-panel ul li#delete-session {
  color: #ff3d3d;
  font-weight: 600;
}

.header .menu-container .menu-panel ul li a {
  color: var(--main-font-colour);
  width: 100%;
  text-decoration: none;
  display: block;
}

.header .menu-container .menu-panel ul li a:visited {
  color: var(--main-font-colour);
}

.poster-container {
  z-index: 2;
}

.poster-container.end {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.poster-container, .next-poster-container {
  aspect-ratio: 2 / 3;
  border-radius: var(--border-radius);
  color: var(--main-font-colour);
  font-weight: 600;
  position: absolute;
  top: 75px;
  left: 50%;
  overflow: visible;
  transform: translate(-50%);
}

.poster-container.hidden, .next-poster-container.hidden {
  display: none;
}

.poster-container .poster, .poster-container .next-poster, .next-poster-container .poster, .next-poster-container .next-poster {
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
}

.poster-container .poster.shrunk, .poster-container .next-poster.shrunk, .next-poster-container .poster.shrunk, .next-poster-container .next-poster.shrunk {
  transform: scale(.45)translate(-60%, -60%);
}

.poster-container .next-poster, .next-poster-container .next-poster {
  box-shadow: 0 10px 38px -19px #00000080;
}

.poster-container .movie-metadata, .next-poster-container .movie-metadata {
  width: 50%;
  opacity: 0;
  pointer-events: none;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
  transition: all .15s;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  overflow: scroll;
}

.poster-container .movie-metadata .providers-header, .next-poster-container .movie-metadata .providers-header {
  font-size: 14px;
  font-style: italic;
}

.poster-container .movie-metadata a, .next-poster-container .movie-metadata a {
  color: var(--main-font-colour);
  text-decoration: underline;
}

.poster-container .movie-metadata a .title, .next-poster-container .movie-metadata a .title {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  display: -webkit-box;
}

.poster-container .movie-metadata a:visited, .next-poster-container .movie-metadata a:visited {
  color: var(--main-font-colour);
}

.poster-container .movie-metadata.visible, .next-poster-container .movie-metadata.visible {
  opacity: 1;
  pointer-events: all;
  display: flex;
}

.poster-container .movie-metadata .genres, .next-poster-container .movie-metadata .genres {
  min-height: 1rem;
  width: 100%;
  gap: .5rem;
  display: flex;
  overflow: scroll;
}

.poster-container .movie-metadata .genres .genre, .next-poster-container .movie-metadata .genres .genre {
  border: 1px solid var(--chip-colour);
  border-radius: 5px;
  flex-shrink: 0;
  padding: 3px 8px;
  font-size: 10px;
}

.poster-container .movie-metadata #providers, .next-poster-container .movie-metadata #providers {
  min-height: 0;
  gap: 1rem;
  display: flex;
  overflow: scroll;
}

.poster-container .movie-metadata #providers .provider, .next-poster-container .movie-metadata #providers .provider {
  min-width: 2rem;
  height: 2rem;
  background-size: cover;
  border-radius: 5px;
  flex-shrink: 0;
}

.poster-container .synopsis, .next-poster-container .synopsis {
  height: 35%;
  opacity: 0;
  pointer-events: none;
  font-weight: 500;
  transition: all .15s;
  position: absolute;
  top: 55%;
  overflow: scroll;
}

.poster-container .synopsis.visible, .next-poster-container .synopsis.visible {
  opacity: 1;
  pointer-events: all;
  display: flex;
}

.poster-container .dismiss, .next-poster-container .dismiss {
  height: 4rem;
  width: 100%;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  font-weight: 400;
  transition: all .15s;
  display: none;
  position: absolute;
  bottom: 0;
}

.poster-container .dismiss.visible, .next-poster-container .dismiss.visible {
  opacity: 1;
  pointer-events: all;
  height: auto;
  width: 100%;
  display: flex;
}

.buttons {
  width: 100%;
  min-width: 320px;
  max-width: 550px;
  z-index: 10;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 3rem 1rem;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (display-mode: standalone) {
  .buttons {
    padding-bottom: 4rem;
  }
}

.buttons .liked-icon {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.buttons.hidden {
  opacity: 0;
  pointer-events: none;
}

.buttons .btn {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: all .1s;
  display: flex;
}

.buttons .btn.like {
  width: 4.5rem;
  background-color: #eaf6eb;
}

.buttons .btn.dislike {
  width: 4.5rem;
  background-color: #f5f3ea;
}

.buttons .btn:active, .buttons .btn:focus {
  transform: scale(.9);
}

.error {
  height: 100vh;
  width: 100vw;
  background-color: var(--base-colour);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.error .error-message {
  font-size: 18px;
  font-weight: 600;
}

/*# sourceMappingURL=app.cd8b826e.css.map */
